<script setup lang="ts">
const config = useRuntimeConfig();
const bridge128Url = computed(
  () => '' /* TODO: Enable link config.public.bridge128Url */
);
</script>

<template>
  <div class="bg-brand-navy-600">
    <Container>
      <div class="grid grid-cols-[auto_121px] md:grid-cols-[235px_auto]">
        <div class="hidden md:block relative">
          <NuxtImg src="/images/levels.png" alt="Levels" class="levels-image" />
        </div>
        <div class="col-span-2 flex md:hidden items-center pt-[29px] pb-3">
          <NuxtIcon name="logo-square" filled class="mobile-logo mr-2.5" />
          <h2 class="text-white text-[26px] leading-[34px] font-medium">
            Improve your game with Bridge128
          </h2>
        </div>
        <div class="flex-col text-white pt-0 pb-[29px] md:pt-[29px]">
          <h2 class="hidden md:block text-[28px] leading-9 font-medium mb-1.5">
            Improve your game with Bridge128
          </h2>
          <p class="text-lg leading-[22px] mb-5 opacity-60 md:opacity-100">
            Learn new strategies, gain confidence and rank higher with classes
            for all levels on our e-learning site
            <NuxtLink
              :to="bridge128Url"
              target="_blank"
              class="hidden md:inline underline"
            >
              Bridge128.com
            </NuxtLink>
          </p>
          <Button
            :to="bridge128Url"
            target="_blank"
            color="white"
            class-name="!font-medium"
          >
            Learn on Bridge128
          </Button>
        </div>
        <div class="md:hidden relative">
          <NuxtImg
            src="/images/levels-mobile.png"
            alt="Levels"
            class="levels-image-mobile"
          />
        </div>
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
.levels-image {
  @apply min-w-[280px] h-[312px] absolute -bottom-[50px] -left-12;

  clip: rect(0, 280px, 262px, 0);
}

.levels-image-mobile {
  @apply min-w-[121px] h-[206px] absolute -bottom-6 -right-5;

  clip: rect(0, 121px, 181px, 0);
}

:deep(.mobile-logo svg) {
  @apply w-[59px] h-[59px] mb-0;
}
</style>
